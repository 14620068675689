












import ProviderUtils, { Provider } from '@/utils/ProviderUtils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => Partial<Provider>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    'error-messages': {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },
  },

  computed: {
    internal: {
      get(): Partial<Provider> {
        return this.value || ProviderUtils.provider.generateDefault();
      },
      set(val: Partial<Provider>) {
        this.$emit('input', val);
      },
    },
  },
});






















import ProviderUtils, { Provider } from '@/utils/ProviderUtils';
import ProviderDescriptionForm from '@/components/provider/ProviderDescriptionForm.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    ProviderDescriptionForm,
  },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      delete: boolean;
      errors?: any[];
    };
    item?: Partial<Provider>;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        delete: false,
        errors: undefined,
      },
      item: undefined,
    };
  },

  async activated() {
    try {
      const result = await ProviderUtils.api.get(
        parseInt(this.$route.params?.id)
      );
      this.item = ProviderUtils.provider.generateDefault(result);
      (this.$refs.form as any).resetValidation();
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 404) {
        // TODO: not found
      }
    }
  },

  methods: {
    async handleSubmit() {
      if (this.item) {
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();
          this.item = ProviderUtils.provider.generateDefault(
            await ProviderUtils.api.update(this.item)
          );
          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t('success.edit', [this.$tc('provider.label')]),
            type: 'success',
          });
        } catch (error: any) {
          this.$toast.push({
            text: this.$t('error.edit', [this.$tc('provider.label')]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },
  },
});
